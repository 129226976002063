import React from 'react'
import './footer.css'
import Logo from '../../assets/whitebbglogo.png'
import PlaceIcon from '@mui/icons-material/Place';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link, useNavigate } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';


const Footer = () => {

    const navigate = useNavigate()

    return (
        <>
            <section className='section footer'>
                <div className="footer-logo">
                    <img src={Logo} alt="" />
                    <p>
                        Unleash your untapped potential and achieve extraordinary greatness
                        by joining Bansal Institute
                        - The best IIT-JEE | NEET | Olympiad Institute in Sagar (Madhya Pradesh).
                    </p>

                </div>

                <div className="footer-info">
                    <div className="get-in-touch">
                        <div className='footer-heading'>
                            <div className="icons">
                                <Link className='insta sicon' to='https://www.instagram.com/bansalclasses.sagar/?fbclid=IwAR128Wjn8oP1w_rN-aV59oCB2wTzi4yDT29T_cg1t7HQoJTeVS539s35Whc'><InstagramIcon className='iconn' style={{ fontSize: '2.5rem' }} /></Link>
                                <Link className='fb sicon' to="https://www.facebook.com/profile.php?id=61553343264886&mibextid=2JQ9oc"><FacebookIcon className='iconn' style={{ fontSize: '2.5rem' }} /></Link>
                                <Link className='LinkedIn sicon' to="https://www.linkedin.com/in/bansal-classes-sagar-4396282a0?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" ><LinkedInIcon style={{ fontSize: '2.5rem' }} /></Link>
                                <Link className='yt sicon' to="https://youtube.com/@BansalClasses-Sagar?si=CUc9pn9zkqFkdoln"><YouTubeIcon className='iconn' style={{ fontSize: '2.5rem' }} /></Link>
                                <Link className='twiiter sicon' to="https://twitter.com/BansalClassSgo"><TwitterIcon className='iconn' style={{ fontSize: '2.5rem' }} /></Link>
                            </div>
                            <p>
                                Get in touch
                            </p>
                        </div>


                        <div className='get-in-touch-box'>
                            <PlaceIcon className='icon-footer' />
                            <p className='footer-para'>Opposite UTD ground, Near Civil Lines, Manorama Colony,
                                Sagar(M.P.) 470001</p>
                        </div>
                        <div className='get-in-touch-box'>
                            <WhatsAppIcon className='icon-footer'/>
                            <p className='footer-para'><Link to='https://api.whatsapp.com/send?phone=916232102601' className='link-p'>6232102601</Link></p>
                        </div>
                        <div className='get-in-touch-box'>
                            <WhatsAppIcon className='icon-footer'/>

                            <p className='footer-para'><Link to='https://api.whatsapp.com/send?phone=916232102602' className='link-p'>6232102602</Link></p>
                        </div>
                        <div className='get-in-touch-box'>
                            <WhatsAppIcon className='icon-footer'/>
                            <p className='footer-para'> <Link to='https://api.whatsapp.com/send?phone=916232102603' className='link-p'>6232102603</Link></p>
                        </div>

                        <div className='get-in-touch-box'>
                            <EmailIcon className='icon-footer' />
                            {/* <Link to="mailto:admin.sagar@bansal.ac.in" className='link-p footer-para'>admin.sagar@bansal.ac.in</Link>
                            <Link to="mailto:sagarbansalclasses@gmail.com" className='link-p'>sagarbansalclasses@gmail.com</Link> */}
                            <p className='footer-para'><Link to="mailto:admin.sagar@bansal.ac.in" className='link-p'>admin.sagar@bansal.ac.in</Link></p>
                        </div>
                        <div className='get-in-touch-box'>
                            <EmailIcon className='icon-footer' />
                            {/* <Link to="mailto:admin.sagar@bansal.ac.in" className='link-p footer-para'>admin.sagar@bansal.ac.in</Link>
                            <Link to="mailto:sagarbansalclasses@gmail.com" className='link-p'>sagarbansalclasses@gmail.com</Link> */}
                            <p className='footer-para'><Link to="mailto:sagarbansalclasses@gmail.com" className='link-p'>sagarbansalclasses@gmail.com</Link></p>
                        </div>

                    </div>

                    <div className="courses-link-footer">
                        <div className='footer-heading'>
                            <p>
                                Our Courses
                            </p>
                        </div>

                        <div className="courses-links-box">
                            <p className='course-link links' onClick={() => navigate('/all-course')}>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>All Courses</span>
                            </p>

                            <p className='course-link links' onClick={() => navigate('/jeeadvanced')}>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>JEE Advanced</span>
                            </p>

                            <p className='course-link links' onClick={() => navigate('/neet-course')}>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>NEET UG</span>
                            </p>

                            <p className='course-link links' onClick={() => navigate('/pre-foundation')}>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>Pre Foundation</span>
                            </p>
                        </div>
                    </div>

                    <div className="quick-links">
                        <div className='footer-heading'>
                            <p>
                                Quick Links
                            </p>
                        </div>

                        <div className="quick-links-box">
                            <p className='quick-link links' onClick={() => navigate('/all-course')}>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>Our Courses</span>
                            </p>

                            <p className='quick-link links' onClick={() => navigate('/boost-registration')}>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>Boost Registration</span>
                            </p>

                            <p className='quick-link links' onClick={() => navigate('/aboutus')}>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>About us</span>
                            </p>

                            <p className='quick-link links' onClick={() => navigate('/contact')}>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>Contact us</span>
                            </p>

                            <p className='quick-link links' onClick={() => navigate('/terms-conditions')}>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>Terms and Conditions</span>
                            </p>

                            <p className='quick-link links' onClick={() => navigate('/privacy-policy')}>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>Privacy Policy</span>
                            </p>

                            {/* <p className='quick-link links'>
                                <ChevronRightIcon className='icon-footer' />
                                <span className='footer-para'>Refund Policy</span>
                            </p> */}
                        </div>


                    </div>


                </div>


            </section>
            {/* <div className="copyright">
                <p>All Right resvered</p>
            </div> */}
        </>
    )
}

export default Footer
import React, { useEffect, useRef, useState } from 'react'
import './stureg.css'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Footer from '../../components/Footer/Footer';
import dayjs from 'dayjs';
import NavBar from '../../components/Navigation bar/NavBar';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import HeadImage from '../../components/Header/HeadImage';

const BoostsReg = () => {

    useEffect(() => {
        document.title = 'Bansal Classes Sagar - Boosts Registration';
    }, []);

    const formRef = useRef(null)
    const scriptUrl = "https://script.google.com/macros/s/AKfycbzqoMa15BtdacK9aEGD0ekpyX_hFrX0dElTAxfHCTqWB8TucxEUh5uEA91q4AmRPlTZ/exec";
    const [loading, setLoading] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [errorPhone, setErrorPhone] = useState(false);
  const [helperTextPhone, setHelperTextPhone] = useState('');
  const [errorZip, setErrorZip] = useState(false);
  const [helperTextZip, setHelperTextZip] = useState('');
 const [dob, setDob] = useState(null); // State to hold the Date of Birth value
  const handleDateChange = (newValue) => {
    setDob(newValue);
  };

     // Handle phone number change with validation

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    const phoneRegex = /^[2-9]{1}[0-9]{9}$/;  // Basic phone number validation (10 digits)

    if (!phoneRegex.test(value)) {
      setErrorPhone(true);
      setHelperTextPhone('Please enter a valid 10-digit phone number.');
    } else {
      setErrorPhone(false);
      setHelperTextPhone('');
    }
  };

  // Handle zip code change with validation
  const handleZipChange = (e) => {
    const value = e.target.value;
    setZipCode(value);

    // Validate zip code (5 or 6 digits)
    const zipRegex = /^[0-9]{6}$/;  // Zip code validation for 5 or 6 digits

    if (!zipRegex.test(value)) {
      setErrorZip(true);
      setHelperTextZip('Zip code must be 6 digits.');
    } else {
      setErrorZip(false);
      setHelperTextZip('');
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (errorPhone || phoneNumber === '' || errorZip || zipCode === '') {
      alert('Please fill the required information correctly before submitting.');
      return;
    }

        // Set the current timestamp before submitting the form
        const timestampField = formRef.current.querySelector("input[name='timestamp']");
        timestampField.value = new Date().toISOString(); // Store the timestamp in ISO format
        setLoading(true);
        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current),
        })
        .then(res => {
            if (res.ok) {
                setLoading(false);
                formRef.current.reset();
                setGender("");
                setClasses("");
                setMode("");
                setBoard("");
                setDateSelected("");
                setConfirmationVisible(true); 
                setDob(null); // Show confirmation popup
            } else {
                console.error("Error with submission: ", res.statusText);
                setLoading(false);
            }
        })
        .catch(err => {
            console.error("Fetch error: ", err);
            setLoading(false);
        });
    };
    

    const closePopup = () => {
        setConfirmationVisible(false); // Hide popup
    };
    const [gender, setGender] = React.useState('');
    // const [value, setValue] = React.useState('');
    const [classes, setClasses] = React.useState('');
    const [course, setCourse] = React.useState('');
    const [mode, setMode] = React.useState('');
    const [board, setBoard] = React.useState([]);
    const [dateSelected, setDateSelected] = React.useState();
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const dayAfter = dayjs().add(2, 'day');


    const handleChangeGender = (event) => {
        setGender(event.target.value);
        console.log(gender)
    };

    const handleMode = (e) => {
        e.preventDefault()
        setMode(e.target.value);
        // console.log(e.target.value);
    }

    const handleCourse = (e) => {
        e.preventDefault()
        setCourse(e.target.value);
    }

    const handleBoard = (e) => {
        e.preventDefault()
        setBoard(e.target.value);
        // console.log(e.target.value);
    }

    // const startDate = dayjs().add(3, 'day');
    // console.log(startDate)

    const onlineDateList = ["9/02/2025", "16/02/2025", "23/02/2025", "At Center"]
    const offlineDateList = ["9/02/2025", "16/02/2025", "23/02/2025", "At Center"]




    return (

        <>
            <NavBar />

            <HeadImage title="Boosts Exam Registrations" />

            <div className="registration-form">
                <div className="heading">
                    <p className='form-reg-heading'>Registration Form</p>
                    <p className='form-reg-subheading'>please fill all the required informations</p>
                </div>
                <div className="boxform">
                    <form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
                        {/* <div className="form-box"> */}


                        {/* Hidden input for storing timestamp */}
                        <input type="hidden" name="timestamp" />
                        <div className="spec-form">
                            <TextField
                                required
                                id="outlined-required"
                                label="Your First Name"
                                name="First Name"
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Your Last Name"
                                name='Last Name'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Father's Name"
                                name="Father's Name"
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="Mother's Name"
                                name="Mother's Name"
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <FormControl required fullWidth>
                                <InputLabel id="gender-label">Gender</InputLabel>
                                <Select
                                    labelId="gender-label"
                                    id="gender"
                                    name="gender"
                                    value={gender}
                                    onChange={handleChangeGender}
                                    label="Gender"

                                >
                                    <MenuItem value={"male"}>Male</MenuItem>
                                    <MenuItem value={"female"}>Female</MenuItem>
                                    <MenuItem value={"other"}>Other</MenuItem>
                                </Select>
                            </FormControl>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateField']}>
                                    <DateField label="Date of Birth" name='Date of Birth'
                                        className='date-picker'
                                        format='DD/MM/YYYY'
                                        onChange={handleDateChange} // Set date value
                                        defaultValue=''
                                        value={dob} />
                                </DemoContainer>
                            </LocalizationProvider>

                            <FormControl required fullWidth>
                                <InputLabel id="class-label">Class</InputLabel>
                                <Select
                                    labelId="class-label"
                                    id="class"
                                    name="class"
                                    value={classes}
                                    onChange={(event) => {
                                        setClasses(event.target.value)
                                    }}
                                    label="Class"
                                    defaultValue={"7"}
                                    placeholder='Select Class'
                                >
                                    <MenuItem value={"7"}>7<sup>th</sup></MenuItem>
                                    <MenuItem value={"8"}>8<sup>th</sup></MenuItem>
                                    <MenuItem value={"9"}>9<sup>th</sup></MenuItem>
                                    <MenuItem value={"10"}>10<sup>th</sup></MenuItem>
                                    <MenuItem value={"11"}>11<sup>th</sup></MenuItem>
                                    <MenuItem value={"12"}>12<sup>th</sup></MenuItem>
                                    <MenuItem value={"13"}>12<sup>th</sup> Passed</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl required fullWidth>
                                <InputLabel id="Course-label">Course</InputLabel>
                                <Select
                                    labelId="Course-label"
                                    id="Course"
                                    name="Course"
                                    value={course}
                                    onChange={handleCourse}
                                    label="Course"
                                >
                                    <MenuItem value={"JEE"} >JEE</MenuItem>
                                    <MenuItem value={"NEET"} >NEET</MenuItem>
                                    <MenuItem value={"Foundation (7th-10th)"}>Foundation (7th-10th)</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                required
                                id="outlined-required"
                                label="School Name"
                                name="School Name"
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <FormControl required fullWidth>
                                <InputLabel id="board-name">Select Board</InputLabel>
                                <Select
                                    labelId="board-name-label"
                                    id="board-name"
                                    name="board-name"
                                    value={board}
                                    onChange={handleBoard}
                                    label="board name"
                                >
                                    <MenuItem value={"cbse"} >CBSE</MenuItem>
                                    <MenuItem value={"icse"} >ICSE</MenuItem>
                                    <MenuItem value={"other"} >Other</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl required fullWidth>
                                <InputLabel id="Test-Mode-label">Test Mode</InputLabel>
                                <Select
                                    labelId="Test-Mode-label"
                                    id="Test-Mode"
                                    name="Test-Mode"
                                    value={mode}
                                    onChange={handleMode}
                                    label="Test Mode"
                                >
                                    <MenuItem value={"online"} >Online</MenuItem>
                                    <MenuItem value={"offline"} >Offline</MenuItem>
                                </Select>
                            </FormControl>



                            {
                                mode === "offline" ?
                                    // <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    //     <DemoContainer components={['DateField']}>
                                    //         <DateField label="Test Date" name='Test Date'
                                    //             className='date-picker'
                                    //             helperText='Select Any Date after 3 days from present date '
                                    //             format='DD/MM/YYYY'
                                    //             disablePast
                                    //             minDate={dayAfter}
                                    //         />
                                    //     </DemoContainer>
                                    // </LocalizationProvider>
                                    <FormControl required fullWidth>
                                    <InputLabel id="Mode-label">Select Date</InputLabel>
                                    <Select
                                        labelId="Test-offline-date"
                                        id="Test-date"
                                        name="Test Date"
                                        value={dateSelected}
                                        onChange={(e) => setDateSelected(e.target.value)}
                                        label="Test Mode"
                                    >

                                        {
                                            offlineDateList.map((date) =>
                                                <MenuItem value={date} >{date}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>

                                    :

                                    <FormControl required fullWidth>
                                        <InputLabel id="Mode-label">Select Date</InputLabel>
                                        <Select
                                            labelId="Test-online-date"
                                            id="Test-date"
                                            name="Test Date"
                                            value={dateSelected}
                                            onChange={(e) => setDateSelected(e.target.value)}
                                            label="Test Mode"
                                        >

                                            {
                                                onlineDateList.map((date) =>
                                                    <MenuItem value={date} >{date}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>

                            }


                            <TextField
                                required
                                id="outlined-required"
                                label="Address"
                                name='address'
                                rows={4}

                                className='addressInput'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="City"
                                name='city'
                                rows={4}
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <TextField
                                required
                                id="outlined-required"
                                label="State"
                                name='state'
                            // rows={4}
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />
                             {/* Zip Code Field */}
      <TextField
        required
        id="outlined-required-zip"
        label="Zip Code"
        name="zip"
        value={zipCode}
        onChange={handleZipChange}
        error={errorZip}
        helperText={helperTextZip}
        placeholder="Enter your zip code"
        type="number"
        inputProps={{ maxLength: 6 }}
      />

                            {/* Phone Number Field */}
      <TextField
        required
        id="outlined-required-phone"
        label="Phone Number"
        name="phone-number"
        value={phoneNumber}
        onChange={handlePhoneChange}
        error={errorPhone}
        helperText={helperTextPhone}
        placeholder="Enter your phone number"
      />

     
                            <TextField
                                id="outlined-required"
                                label="Alternative Phone Number"
                                name='alternative-phone'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />


                            <TextField
                                required
                                id="outlined-required"
                                label="Email"
                                type='email'
                                name='email'
                                className='email'
                            // defaultValue="Hello World" 
                            // placeholder='Your First'
                            />

                            <input type="submit" className="btn-regform myBtn"
                                value={loading ? "Loading..." : "Submit"} />
                        </div>

                    </form >
                    {/* </div> */}
                </div >
            </div >
            {confirmationVisible && (
                <div className="confirmation-popup">
                    <div className="popup-content">
                        <p>Form submitted successfully!</p>
                        <button onClick={closePopup}>OK</button>
                    </div>
                </div>
            )}
            <Footer />

        </>
    )
}

export default BoostsReg